import React, { Fragment, useState } from "react";
import { Accordion, AccordionHeader, AccordionBody, } from "@material-tailwind/react";


function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`${id === open ? "rotate-180" : ""
                } h-7 w-7 transition-transform`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="#fbbd69"
            strokeWidth={2}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
        </svg>
    );
}


const CommonQuestion = () => {
    const [open, setOpen] = useState(0);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    return (
        <>
            <section className="onboarding-section">
                <div className="container mx-auto px-4">
                    
                    <div className="max-width1000">
                        <Fragment>
                            <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(1)}>
                                    How long will it take to get setup?
                                </AccordionHeader>
                                <AccordionBody>  
                                    Usually everything is complete within 4-7 business days unless a tech is required. We use local technicians to your area so depending on availability if a tech is required it can take a little longer. Normally a tech is only required if you are in a new construction or if you know there is no coax in your home.
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(2)}>
                                    Should I schedule the cancelation of my other internet provider now?
                                </AccordionHeader>
                                <AccordionBody>
                                    Not yet. It is best to wait until you are fully hooked up before canceling your current provider. This will ensure you will not go without internet. 
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(3)}>
                                    What if I discover I need a tech?
                                </AccordionHeader>
                                <AccordionBody>
                                    No problem. Just let us know by text and we can get one scheduled for you. It normally takes 24 hours to get one scheduled. Once scheduled it normally takes anywhere form a day to a week for them to come out.
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(4)}>
                                    When will I get my modem in the mail?
                                </AccordionHeader>
                                <AccordionBody>
                                We generally ship your modem out to arrive on the day you requested for your internet to be turned on.
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 5} icon={<Icon id={5} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(5)}>
                                    What do I do when I get my modem in the mail?
                                </AccordionHeader>
                                <AccordionBody>
                                    Feel free to follow the instructions in the box and once done text us "Plugged in". We will respond with the next steps. 
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 6} icon={<Icon id={6} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(6)}>
                                    How do I get my wifi password?
                                </AccordionHeader>
                                <AccordionBody>
                                    Once you have your modem plugged in and all the lights are on feel free to text us "Password Please" and we will send you your wifi password. 
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 7} icon={<Icon id={7} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(7)}>
                                    How does my home phone number port?
                                </AccordionHeader>
                                <AccordionBody>
                                    Great question. First off make sure you do not cancel your current phone service until the phone number has ported. We will let you know when this is completed.
                                    To port your number the first thing we will need is a copy of your current phone bill. This bill needs to be your most recent and show your name, phone and account number on it.
                                    You will be receive an email from us with instructions on how to submit this bill. 
                                </AccordionBody>
                            </Accordion>
                        </Fragment>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CommonQuestion
